import { Box, Flex, FlexProps, Spinner } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import {
  GET_RELATED_NEWS_QUERY_KEY,
  getRelatedNews,
} from "../api/newsScreenApi";
import { NewsCard } from "../../../components/news/NewsCard";
import { LayoutStyle } from "../../../types";

interface RelatedNewsProps {
  articleId: number;
  showDividers?: boolean;
}

export function RelatedNews({
  articleId,
  showDividers = false,
  cardSize = "l",
  ...props
}: RelatedNewsProps & { cardSize?: LayoutStyle["size"] } & FlexProps) {
  const { isLoading, data, error } = useQuery({
    queryKey: [...GET_RELATED_NEWS_QUERY_KEY, articleId],
    queryFn: () => getRelatedNews(articleId ?? ""),
    enabled: !!articleId,
  });

  if (isLoading) {
    return <Spinner color="blaze.blaze" />;
  }

  if (!data || error) {
    return null;
  }

  return (
    <Flex flexDirection="column" gap="32px" {...props}>
      {data?.data.payload.results.map((related, i) => {
        return (
          <>
            <NewsCard
              key={related.id}
              content={related}
              size={cardSize ?? "l"}
            />
            {showDividers && i !== data?.data.payload.results.length - 1 ? (
              <Box
                key={related.id + "-divider"}
                width="100%"
                height="1px"
                bg="transparent.white.10"
              />
            ) : null}
          </>
        );
      })}
    </Flex>
  );
}
