import { Box } from "@chakra-ui/react";
import { rootComponentsStore } from "../layouts/RootLayout";
import { useHookstate } from "@hookstate/core";
import { SettingsMenu } from "../../screens/reader/components/settingsMenu/SettingsMenu";
import { AllPagesMenu } from "../../screens/reader/components/allPagesMenu/AllPagesMenu";
import { AddToMenuWrapper } from "../../screens/reader/components/addToMenu/AddToMenu";
import { CommentsMenuWrapper } from "../../screens/reader/components/commentsMenu/CommentsMenu";
import { ArtistProfile } from "../../screens/artist/ArtistProfile";
import { NAVBAR_HEIGHT, SLIDE_IN_MENUS } from "../../screens/root/constants";
import { ComicDetailsWrapper } from "./comicDetails/ComicDetails";
import { ReleaseInfoMenuWrapper } from "./releaseInfo/ReleaseInfoMenu";
import { cloneElement } from "react";
import { AnimatePresence, motion } from "framer-motion";

interface SlideInMenuProps {
  isTopBarHidden?: boolean;
  openMenu: string | undefined;
  onCloseMenu: () => void;
}

export function SlideInMenu({
  isTopBarHidden = false,
  openMenu,
  onCloseMenu,
}: SlideInMenuProps) {
  const inReader = useHookstate(rootComponentsStore.inReader).get();
  const inViewAllSection = window.location.pathname.includes("cms/");

  const isSlidingUp = !inReader; // Use bottom-to-top animation when not in reader

  return (
    <Box
      id="slideInMenu"
      position="fixed"
      width={isSlidingUp ? "600px" : "400px"}
      maxWidth="100%"
      height={isSlidingUp ? "full" : `calc(100% - ${NAVBAR_HEIGHT}px)`}
      background="transparent"
      zIndex={!inReader && !inViewAllSection ? 2 : 1401}
      left={isSlidingUp ? "50%" : "auto"}
      bottom={isSlidingUp ? (openMenu ? "0" : "-100%") : "auto"}
      right={isSlidingUp ? "auto" : openMenu ? 0 : -400}
      top={isSlidingUp ? "auto" : isTopBarHidden ? 0 : NAVBAR_HEIGHT + "px"}
      transition={
        isSlidingUp
          ? "bottom 0.3s ease-out, height 0.2s ease-out"
          : "right .2s linear, top .2s ease-out, height .2s ease-out"
      }
      style={isSlidingUp ? { transform: "translateX(-50%)" } : {}}
    >
      <Box
        onClick={(e) => e.stopPropagation()}
        position="relative"
        maxWidth="inherit"
        height="calc(100% - 20px)"
        margin="10px"
        background="charcoal.charcoal"
        backdropFilter="blur(12px)"
        transition="right .15s linear"
        transitionDelay={openMenu ? ".1s" : "0"}
        overflow="hidden"
        borderRadius="16px"
        border="1px solid var(--White-Transparency-White-20, rgba(255, 255, 255, 0.20))"
      >
        <AnimatePresence mode="sync">
          <Box
            position="relative"
            height="100%"
            width="100%"
            key={openMenu}
            as={motion.div}
            initial="initial"
            animate="animate"
            variants={{
              initial: { opacity: 0 },
              animate: { opacity: 1 },
              exit: {
                opacity: 0,
                transition: { type: "tween", duration: 0.2 },
              },
            }}
          >
            {openMenu && cloneElement(menus?.[openMenu], { onCloseMenu })}
          </Box>
        </AnimatePresence>
      </Box>
    </Box>
  );
}

const menus = {
  [SLIDE_IN_MENUS.settings]: <SettingsMenu />,
  [SLIDE_IN_MENUS.allPages]: <AllPagesMenu />,
  [SLIDE_IN_MENUS.addTo]: <AddToMenuWrapper />,
  [SLIDE_IN_MENUS.comments]: <CommentsMenuWrapper />,
  [SLIDE_IN_MENUS.comicDetails]: <ComicDetailsWrapper />,
  [SLIDE_IN_MENUS.publisher]: <ArtistProfile />,
  [SLIDE_IN_MENUS.releaseInfo]: <ReleaseInfoMenuWrapper />,
};
