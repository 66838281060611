import { Text, Flex, Spinner, Divider } from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Comic, Release } from "../../../types";
import {
  getComicReleases,
  getRelease,
  useSlideInMenu,
} from "../hooks/useSlideInMenu";
import { useComicReleasesData } from "../../../screens/reader/hooks/hookstate/useComicReleasesData";
import { useEffect, useState } from "react";
import { ReleaseInfoHeader } from "./ReleaseInfoHeader";
import { ReleaseInfoCoverCarousel } from "./ReleaseInfoCoverCarousel";
import { ReleaseInfoPageCarousel } from "./ReleaseInfoPageCarousel";
import { ShowMoreSection } from "../../containers/ShowMoreSection";
import { ArtistSection } from "../comicDetails/ArtistSection";
import { ComicContributorList } from "../comicDetails/ComicDescription";
import { ComicLabels } from "../comicDetails/ComicLabels";
import { ReleaseInfoActions } from "./ReleaseInfoActions";
import { useReleaseData } from "../../../screens/reader/hooks/hookstate/useReleaseData";
import { SlideInHeader } from "../components/SlideInHeader";
import { SLIDE_IN_MENUS } from "../../../screens/root/constants";
import PaywallProvider, {
  usePaywall,
} from "../../payments/contexts/PaywallContext";
import { SubscriptionModalBody } from "../../payments/SubscriptionModal";
import { FUNNEL_TYPES } from "../../funnel/utils/contants";
import { PurchaseReleaseModalBody } from "../../payments/components/PurchaseReleaseModalBody";
import { PurchaseReleaseModalFooter } from "../../payments/components/PurchaseReleaseModalFooter";
import { AnimatePresence } from "framer-motion";
import { PageSlide } from "../../pageSlide/PageSlide";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";

interface ReleaseInfoMenuProps {
  onCloseMenu?: () => void;
}
export function ReleaseInfoMenuWrapper(props: ReleaseInfoMenuProps) {
  return (
    <PaywallProvider>
      <ReleaseInfoMenu {...props} />
    </PaywallProvider>
  );
}

export function ReleaseInfoMenu({ onCloseMenu }: ReleaseInfoMenuProps) {
  const isSmallBreakpoint = useIsSmallBreakpoint();

  const { releaseUuid, comicSlug } = useSlideInMenu();
  const queryClient = useQueryClient();

  const paywallContext = usePaywall();
  const { paywallConfig, setPaywallConfig } = paywallContext ?? {};

  const [activeReleaseUuid, setActiveReleaseUuid] = useState(releaseUuid);

  const { comicReleasesData } = useComicReleasesData();
  const { releaseData } = useReleaseData();

  const existsCachedRelease =
    releaseData && releaseData.key === activeReleaseUuid;

  const { data: fetchedRelease, isLoading } = useQuery({
    enabled: !existsCachedRelease,
    queryKey: ["releaseDetailsRelease", activeReleaseUuid],
    queryFn: () => {
      return getRelease(activeReleaseUuid ?? "");
    },
  });

  const { data: fetchedComicReleasesResponse } = useQuery({
    enabled: !comicReleasesData,
    queryKey: ["fetchedComicReleases", comicSlug],
    queryFn: () => {
      return getComicReleases(comicSlug ?? "");
    },
  });

  const fetchedComicReleasesData =
    fetchedComicReleasesResponse?.data.payload.results;
  var releases =
    (comicReleasesData as Release[]) || (fetchedComicReleasesData ?? []);

  const activeRelease = existsCachedRelease
    ? releaseData
    : fetchedRelease?.data.payload.results;

  useEffect(() => {
    /// We need to update the cached release data from home sections
    // so that the changes get reflected
    queryClient.setQueryData(
      [`${activeRelease?.entity_type}-${activeRelease?.id}`],
      activeRelease,
    );
  }, [activeRelease?.release_user_purchase]);

  if (!activeRelease && !releases) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <Spinner color="blaze.blaze" />
      </Flex>
    );
  }

  const comic = activeRelease?.comic as Comic;
  return (
    <>
      <SlideInHeader
        handleBack={paywallConfig ? () => setPaywallConfig?.() : undefined}
        openMenu={SLIDE_IN_MENUS.releaseInfo}
        onCloseMenu={onCloseMenu}
      />
      <AnimatePresence initial={false} mode="wait">
        {paywallConfig ? (
          <PageSlide
            key={paywallConfig.type}
            px="24px"
            pt="96px"
            pb="24px"
            gap="24px"
          >
            {paywallConfig.type === FUNNEL_TYPES.subscription ? (
              <SubscriptionModalBody
                isModal={isSmallBreakpoint}
                hideCloseButton
                onClose={() => setPaywallConfig?.()}
                onSuccess={() => setPaywallConfig?.()}
                setFunnelType={() => {}}
              />
            ) : (
              <>
                <PurchaseReleaseModalBody
                  release={activeRelease}
                  onClose={() => setPaywallConfig?.()}
                  onSuccess={() => {}}
                  setFunnelType={() => {}}
                />
                {activeRelease ? (
                  <PurchaseReleaseModalFooter
                    isModal={isSmallBreakpoint}
                    release={activeRelease}
                    onClose={() => setPaywallConfig?.()}
                    onSuccess={() => {}}
                    setFunnelType={() => {}}
                  />
                ) : null}
              </>
            )}
          </PageSlide>
        ) : (
          <PageSlide ltr paddingBottom="24px" paddingTop="64px">
            <ReleaseInfoCoverCarousel
              releases={releases}
              activeReleaseUuid={activeReleaseUuid}
              setActiveReleaseUuid={setActiveReleaseUuid}
            />
            {!activeRelease || isLoading ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
              >
                <Spinner color="blaze.blaze" />
              </Flex>
            ) : (
              <Flex flexDirection="column" pt="16px" px="24px" gap="16px">
                <ReleaseInfoHeader
                  comic={comic}
                  release={activeRelease as Release}
                />
                <Divider color="transparent.white.10" />
                <ReleaseInfoActions release={activeRelease as Release} />
                <Divider color="transparent.white.10" />
                <ReleaseInfoPageCarousel release={activeRelease as Release} />
                <Divider color="transparent.white.10" />
                <ComicLabels comicData={comic} />
                <Divider color="transparent.white.10" />
                <ShowMoreSection
                  collapsedHeight={150}
                  expandedMarginBottom="24px"
                >
                  <Text
                    fontFamily="Roboto"
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="22.4px"
                    color="neutral.300"
                  >
                    {activeRelease.description}
                  </Text>
                </ShowMoreSection>
                {comic.artist && <ArtistSection artist={comic.artist} />}
                {comic.contributors && (
                  <>
                    <Divider color="transparent.white.10" />
                    <ComicContributorList comicData={comic} byRole />
                  </>
                )}
              </Flex>
            )}
          </PageSlide>
        )}
      </AnimatePresence>
    </>
  );
}
