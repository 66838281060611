import { Button, Flex } from "@chakra-ui/react";
import { ButtonCarousel } from "../../../components/carousel/ButtonCarousel";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useNewsCategories } from "../hooks/useNewsCategories";
import { t } from "i18next";
import { CloseIcon } from "@chakra-ui/icons";

export function NewsCategoriesCarousel() {
  const navigate = useNavigate();

  const [urlSearchParams] = useSearchParams();
  const tag = urlSearchParams.get("tag") ?? "";

  const { newsCategories, activeCategory } = useNewsCategories();

  return (
    <ButtonCarousel
      width="100%"
      wrapperProps={{
        position: "relative",
        overflowX: "auto",
      }}
      slidesToScroll={1}
    >
      <Button
        variant={"filterTag"}
        gap={1.5}
        onClick={() => {
          navigate(`/news${tag ? `?tag=${tag}` : ""}`);
        }}
        isActive={!activeCategory?.id}
        _active={{ borderColor: "blaze.blaze" }}
      >
        {t("screens.news.all")}
      </Button>
      {newsCategories?.map(({ display_name, slug, id }, i) => {
        return (
          <Flex key={i} paddingLeft="8px">
            <Button
              variant={"filterTag"}
              gap={1.5}
              onClick={() => {
                navigate(`/news/${slug}/${tag ? `?tag=${tag}` : ""}`);
              }}
              isActive={activeCategory?.id === id}
              _active={{ borderColor: "blaze.blaze" }}
            >
              {display_name}
            </Button>
          </Flex>
        );
      })}
      {tag ? (
        <Flex paddingLeft="8px">
          <Button
            variant="filterTag"
            gap={1.5}
            onClick={() => {
              navigate(window.location.pathname);
            }}
            isActive={true}
            _active={{ borderColor: "blaze.blaze" }}
          >
            {tag}
            <CloseIcon boxSize="8px" />
          </Button>
        </Flex>
      ) : null}
    </ButtonCarousel>
  );
}
