import { Button, Flex } from "@chakra-ui/react";
import { Release } from "../../../types";
import { useUserData } from "../../../hooks/useUserData";
import { useTranslation } from "react-i18next";
import { rootComponentsStore } from "../../layouts/RootLayout";
import { useNavigateToReaderWithContext } from "../../../screens/reader/hooks/useNavigateToReaderWithContext";
import { NavigationTrigger } from "../../../screens/reader/types";
import { setFunnelModalConfig } from "../../funnel/FunnelModal";
import { FUNNEL_TYPES } from "../../funnel/utils/contants";
import { useGetNavigationContext } from "../../../hooks/useGetNavigationContext";
import { FunnelContextArea } from "../../funnel/types";
import { DiscountBanner } from "../../payments/components/DiscountBanner";
import { usePaywall } from "../../payments/contexts/PaywallContext";

export interface ReleaseInfoActionsProps {
  release: Release;
}

export function ReleaseInfoActions({ release }: ReleaseInfoActionsProps) {
  const paywallContext = usePaywall();
  const { setPaywallConfig } = paywallContext ?? {};

  const { t } = useTranslation();
  const { userData } = useUserData();
  const userIsGold = userData?.gold ?? false;
  const {
    is_free,
    release_user_purchase,
    premium_only,
    ppb_price_point,
    ppb_gold_price_point,
    user_read_status,
  } = release;

  const releaseIsLocked =
    !is_free && !release_user_purchase && (!userIsGold || premium_only);
  const navigationContext =
    rootComponentsStore.slideInMenuConfig.get()?.navigationContext;

  const { goToCTAUrl } = useNavigateToReaderWithContext(
    {
      ...navigationContext,
      releaseToNavigate: release,
      trigger: releaseIsLocked
        ? NavigationTrigger.previewReleaseButtonReleaseContextual
        : release.user_read_status
          ? NavigationTrigger.continueReadingButtonReleaseContextual
          : NavigationTrigger.startReadingButtonReleaseContextual,
    },
    [release],
  );

  const funnelContext = useGetNavigationContext({
    contextArea: FunnelContextArea.releaseDetails,
    release,
  });

  return (
    <Flex flexDirection="column" alignItems="center" width="100%" gap="10px">
      {premium_only &&
      ppb_price_point &&
      ppb_gold_price_point &&
      !release_user_purchase &&
      ppb_price_point !== ppb_gold_price_point ? (
        <DiscountBanner
          price={ppb_price_point}
          goldPrice={ppb_gold_price_point}
          isGold={userIsGold}
        />
      ) : null}
      <Button
        variant={releaseIsLocked ? "tertiary" : "primary"}
        width="100%"
        onClick={goToCTAUrl}
      >
        {releaseIsLocked
          ? t("components.slideInMenu.releaseInfoMenu.preview")
          : user_read_status
            ? t("components.slideInMenu.releaseInfoMenu.continueReading")
            : t("components.slideInMenu.releaseInfoMenu.startReading")}
      </Button>
      {!is_free && !premium_only && !userIsGold && !release_user_purchase && (
        <Button
          variant={"primary"}
          width="100%"
          onClick={() =>
            setPaywallConfig
              ? setPaywallConfig?.({
                  type: FUNNEL_TYPES.subscription,
                  navigationContext: funnelContext,
                })
              : setFunnelModalConfig({
                  type: FUNNEL_TYPES.subscription,
                  options: {
                    locked: false,
                    modalContext: "modal",
                    navigationContext: funnelContext,
                  },
                })
          }
        >
          {t("components.slideInMenu.releaseInfoMenu.readWithGold")}
        </Button>
      )}
      {!is_free && !release.release_user_purchase && (
        <PayPerBookButtons
          userIsGold={userIsGold ?? false}
          isPremiumOnly={premium_only}
          ppbPrice={ppb_price_point}
          ppbGoldPrice={ppb_gold_price_point}
          release={release}
        />
      )}
    </Flex>
  );
}

interface PayPerBookButtonProps {
  userIsGold: boolean;
  ppbPrice?: number;
  ppbGoldPrice?: number;
  isPremiumOnly: boolean;
  release: Release;
}

function PayPerBookButtons({
  userIsGold,
  ppbGoldPrice,
  ppbPrice,
  isPremiumOnly,
  release,
}: PayPerBookButtonProps) {
  const paywallContext = usePaywall();
  const { setPaywallConfig } = paywallContext ?? {};

  const { t } = useTranslation();
  const funnelContext = useGetNavigationContext({
    contextArea: FunnelContextArea.ppbPurchase,
    release,
  });
  if (!ppbGoldPrice && !ppbPrice) return null;
  const pricesAreDifferent =
    ppbPrice && ppbGoldPrice && ppbGoldPrice !== ppbPrice;
  const priceString = ppbPrice ? `$${(ppbPrice / 100).toFixed(2)}` : "";
  const goldPrice = ppbGoldPrice ? `$${(ppbGoldPrice / 100).toFixed(2)}` : "";

  const thereAre2PricesToShow = pricesAreDifferent && !userIsGold;
  const thereIsPPBAndFreeWithGold = !isPremiumOnly && !userIsGold && ppbPrice;
  const showSecondButton = thereAre2PricesToShow || thereIsPPBAndFreeWithGold;

  return (
    <Flex flexDirection="column" alignItems="center" width="100%" gap="10px">
      {isPremiumOnly ? (
        <Button
          variant="primary"
          width="100%"
          onClick={() => {
            if (!userIsGold && pricesAreDifferent) {
              setPaywallConfig
                ? setPaywallConfig?.({
                    type: FUNNEL_TYPES.subscription,
                    navigationContext: funnelContext,
                  })
                : setFunnelModalConfig({
                    type: FUNNEL_TYPES.subscription,
                    options: {
                      locked: false,
                      modalContext: "modal",
                      navigationContext: funnelContext,
                    },
                  });
            } else {
              setPaywallConfig
                ? setPaywallConfig({
                    type: FUNNEL_TYPES.purchaseRelease,
                    navigationContext: funnelContext,
                  })
                : setFunnelModalConfig({
                    type: FUNNEL_TYPES.purchaseRelease,
                    options: {
                      purchaseRelease: release,
                    },
                  });
            }
          }}
        >
          {!userIsGold && pricesAreDifferent
            ? t("components.slideInMenu.releaseInfoMenu.buyWithGold", {
                price: goldPrice,
              })
            : `${t("components.slideInMenu.releaseInfoMenu.buy")} ${userIsGold && goldPrice ? goldPrice : priceString}`}
        </Button>
      ) : null}
      {showSecondButton && (
        <Button
          variant="tertiary"
          width="100%"
          onClick={() =>
            setPaywallConfig
              ? setPaywallConfig?.({
                  type: FUNNEL_TYPES.purchaseRelease,
                  navigationContext: funnelContext,
                })
              : setFunnelModalConfig({
                  type: FUNNEL_TYPES.purchaseRelease,
                  options: {
                    purchaseRelease: release,
                  },
                })
          }
        >
          {t("components.slideInMenu.releaseInfoMenu.buy")} {priceString}
        </Button>
      )}
    </Flex>
  );
}
