import {
  Box,
  Link,
  ListItem,
  Text,
  UnorderedList,
  Image,
} from "@chakra-ui/react";
import { CSSProperties } from "react";
import parse, {
  DOMNode,
  domToReact,
  attributesToProps,
} from "html-react-parser";

export function HtmlParser({ htmlString }: { htmlString: string }) {
  const customRender = (node: DOMNode) => {
    if (node.type !== "tag") return;

    const { name, children, attribs } = node;
    const parsedChildren = children as DOMNode[];

    const props = attributesToProps(node.attribs);
    const style = props["style"];
    const align = props["align"] as CSSProperties["textAlign"];

    const customReactChildren = domToReact(parsedChildren, {
      replace: customRender,
    });

    switch (name) {
      case "img":
        return <Image src={attribs.src} sx={style} {...props} />;
      case "p":
        return (
          <Text
            fontSize="lg"
            lineHeight="180%"
            color="dune.dune"
            mb={4}
            sx={style}
            align={align}
            {...props}
          >
            {customReactChildren}
          </Text>
        );
      case "a":
        return (
          <Link
            color="blaze.400"
            href={attribs.href}
            isExternal
            sx={style}
            _hover={{
              color: "blaze.300",
            }}
            transition="all .2s linear"
            {...props}
          >
            {customReactChildren}
          </Link>
        );
      case "ul":
        return (
          <UnorderedList mb="32px" sx={style}>
            {customReactChildren}
          </UnorderedList>
        );
      case "li":
        return (
          <ListItem mb="4px" sx={style}>
            {customReactChildren}
          </ListItem>
        );

      case "hr":
        return (
          <Box mb="32px" h="1px" w="full" background="transparent.white.10">
            {customReactChildren}
          </Box>
        );
      default:
        return null;
    }
  };

  return <Box>{parse(htmlString, { replace: customRender })}</Box>;
}
