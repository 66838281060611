import { Button, Flex } from "@chakra-ui/react";
import { Comic } from "../../../types";
import { BookmarkCheckedIcon } from "../../icons/reader/BookmarkCheckedIcon";
import { BookmarkIcon } from "../../icons/BookmarkIcon";
import { rootComponentsStore } from "../../layouts/RootLayout";
import { useHookstate } from "@hookstate/core";
import { useTranslation } from "react-i18next";
import { useToggleComicFavorite } from "../../cards/hooks/useToggleComicFavorite";
import { NavigationTrigger } from "../../../screens/reader/types";
import { useNavigateToReaderWithContext } from "../../../screens/reader/hooks/useNavigateToReaderWithContext";
import { useIsOverlayOpen } from "../../../screens/reader/hooks/hookstate/useIsOverlayOpen";
import { useReleaseData } from "../../../screens/reader/hooks/hookstate/useReleaseData";

export interface ComicDetailsBottomBarProps {
  comicData: Comic;
}

export function ComicDetailsBottomBar({
  comicData,
}: ComicDetailsBottomBarProps) {
  const { toggleIsOverlayOpen } = useIsOverlayOpen();
  const inReader = useHookstate(rootComponentsStore.inReader).get();
  const { releaseData } = useReleaseData();
  const { t } = useTranslation();
  const handleToggleFavorite = useToggleComicFavorite(
    `${comicData.entity_type}-${comicData.id}`,
  );
  const navigationContext = useHookstate(
    rootComponentsStore.slideInMenuConfig,
  ).get()?.navigationContext;

  const { goToCTAUrl } = useNavigateToReaderWithContext({
    ...navigationContext,
    comicToNavigate: comicData,
    trigger: comicData.user_read_status
      ? NavigationTrigger.continueReadingButton
      : NavigationTrigger.startReadingButton,
  });

  const navigate = releaseData?.comic_id !== comicData.id;
  return (
    <Flex
      height="96px"
      justifyContent="center"
      position="fixed"
      width="100%"
      bottom={0}
      right={0}
      flexDirection="row"
      backdropFilter="blur(18px)"
      background="#141517CC" //This is charcoal with 80% opacity
      boxShadow="0px 4px 24px 0px rgba(0, 0, 0, 0.40)"
      sx={{
        borderTopColor: "transparent.white.10",
      }}
      borderTop="1px solid"
      p="24px"
      gap="10px"
    >
      <Button
        id="btnContinueReading"
        variant="primary"
        size="lg"
        width="100%"
        aria-label="Continue reading"
        clipPath="inset(0 0 0 0 round 20px)"
        flex={1}
        textTransform="capitalize"
        onClick={!navigate ? toggleIsOverlayOpen : goToCTAUrl}
      >
        {(!inReader && comicData.user_read_status) ||
        !!comicData.user_read_status
          ? t("components.slideInMenu.comicDetailsMenu.continueReading")
          : t("components.slideInMenu.comicDetailsMenu.startReading")}
      </Button>
      <Button
        variant="tertiary"
        height="100%"
        width="52px"
        borderRadius="20px"
        onClick={handleToggleFavorite}
      >
        {comicData?.is_favorite ? (
          <BookmarkCheckedIcon boxSize="24px" />
        ) : (
          <BookmarkIcon boxSize="24px" />
        )}
      </Button>
    </Flex>
  );
}
