import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { menuTitles } from "../../../screens/reader/components/menuTitles";
import { CloseCircleIcon } from "../../icons/CloseIcon";
import { CaretLeftLargeIcon } from "../../icons/CaretLeftLargeIcon";
import { AnimatePresence, motion } from "framer-motion";

export function SlideInHeader({
  handleBack,
  openMenu,
  onCloseMenu,
}: {
  handleBack?: () => void;
  onCloseMenu?: () => void;
  openMenu: string;
}) {
  return (
    <Flex
      position="absolute"
      top={0}
      left={0}
      zIndex={2}
      borderBottom="1px solid"
      borderColor="transparent.white.10"
      p="16px 24px "
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      background="#141517CC"
      backdropFilter="blur(16px)"
      boxShadow="0px 4px 24px 0px rgba(0, 0, 0, 0.40)"
      style={{ scrollbarWidth: "thin" }}
    >
      <AnimatePresence initial={false} mode="wait">
        {handleBack ? (
          <Box
            as={motion.div}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={{
              initial: { width: 0, marginRight: 0, opacity: 0 },
              animate: {
                marginRight: "10px",
                width: "24px",
                opacity: 1,
                transition: { type: "tween", duration: 0.4 },
              },
              exit: {
                marginRight: 0,
                width: 0,
                opacity: 0,
                transition: { type: "tween", duration: 0.4 },
              },
            }}
          >
            <Button
              minWidth="none"
              variant="iconOnly"
              aria-label="Close the slide in menu"
              onClick={handleBack}
              paddingRight="0"
            >
              <CaretLeftLargeIcon boxSize="24px" />
            </Button>
          </Box>
        ) : null}
      </AnimatePresence>
      <Text
        fontSize="20px"
        fontWeight="600"
        lineHeight="26px"
        fontFamily="Bricolage Grotesque"
        color="dune.dune"
        width="100%"
      >
        {openMenu && menuTitles?.[openMenu]}
      </Text>
      <Button
        variant="iconOnly"
        aria-label="Close the slide in menu"
        onClick={onCloseMenu}
      >
        <CloseCircleIcon w="24px" h="24px" />
      </Button>
    </Flex>
  );
}
