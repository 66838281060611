import { motion } from "framer-motion";
import { Box, BoxProps } from "@chakra-ui/react";

const swipeRightToLeft = {
  initial: { x: "50%", opacity: 0, overflowY: "hidden" },
  animate: {
    x: 0,
    opacity: 1,
    transition: { type: "tween", duration: 0.2 },
    overflowY: "auto",
  },
  exit: {
    x: "50%",
    opacity: 0,
    transition: { type: "tween", duration: 0.2 },
    overflowY: "hidden",
  },
};
const swipeLeftToRight = {
  initial: { x: "-50%", opacity: 0, overflowY: "hidden" },
  animate: {
    x: 0,
    opacity: 1,
    transition: { type: "tween", duration: 0.2 },
    overflowY: "auto",
  },
  exit: {
    x: "-50%",
    opacity: 0,
    transition: { type: "tween", duration: 0.2 },
    overflowY: "hidden",
  },
};

export const PageSlide = ({
  children,
  ltr,
  ...props
}: {
  children: React.ReactNode;
  ltr?: boolean;
} & BoxProps) => {
  return (
    <Box
      as={motion.div}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={ltr ? swipeLeftToRight : swipeRightToLeft}
      position="relative"
      display="flex"
      flexDirection="column"
      overflow="hidden"
      height="100%"
      widt="100%"
      justifyContent="flex-start"
      onAnimationEnd={(e) => {
        e.currentTarget.style.overflowY = "auto";
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
