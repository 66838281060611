import { Box, Flex, Divider, Spinner } from "@chakra-ui/react";
import { t } from "i18next";
import { ComicDetailsHeader } from "./ComicDetailsHeader";
import { ComicDescription } from "./ComicDescription";
import { ComicDetailsTabs } from "./ComicDetailsTabs";
import { ArtistSection } from "./ArtistSection";
import { Stats } from "../../../screens/reader/components/shared/Stats";
import { useSlideInMenu } from "../hooks/useSlideInMenu";
import { getAudienceShort } from "../../../screens/reader/utils/comicFacetGroups";
import { ComicDetailsBottomBar } from "./ComicDetailsBottomBar";
import { Comic } from "../../../types";
import { ShowMoreSection } from "../../containers/ShowMoreSection";
import { useQuery } from "@tanstack/react-query";
import { formatLargeNumber } from "../../../utils/formatLargeNumber";
import { ComicLabels } from "./ComicLabels";
import { CrowdfundingBanner } from "./CrowdfundingBanner";
import { SlideInHeader } from "../components/SlideInHeader";
import { SLIDE_IN_MENUS } from "../../../screens/root/constants";
import { SubscriptionModalBody } from "../../payments/SubscriptionModal";
import PaywallProvider, {
  usePaywall,
} from "../../payments/contexts/PaywallContext";
import { AnimatePresence } from "framer-motion";
import { PageSlide } from "../../pageSlide/PageSlide";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";

interface ComicDetailsProps {
  onCloseMenu?: () => void;
}

export function ComicDetailsWrapper(props: ComicDetailsProps) {
  return (
    <PaywallProvider>
      <ComicDetails {...props} />
    </PaywallProvider>
  );
}

export function ComicDetails({ onCloseMenu }: ComicDetailsProps) {
  const isSmallBreakpoint = useIsSmallBreakpoint();

  const paywallContext = usePaywall();
  const { paywallConfig, setPaywallConfig } = paywallContext ?? {};

  const { comicCacheKey, isFetched } = useSlideInMenu(true);
  const { data: comicData } = useQuery<Comic>({
    queryKey: [comicCacheKey],
    networkMode: "offlineFirst",
    staleTime: Infinity,
  });

  if (!comicData || !isFetched) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <Spinner color="blaze.blaze" />
      </Flex>
    );
  }

  return (
    <>
      <SlideInHeader
        handleBack={paywallConfig ? () => setPaywallConfig?.() : undefined}
        openMenu={SLIDE_IN_MENUS.comicDetails}
        onCloseMenu={onCloseMenu}
      />
      <AnimatePresence mode="wait" initial={false}>
        {paywallConfig ? (
          <PageSlide key={paywallConfig.type} p="24px" pb="24px" pt="96px">
            <SubscriptionModalBody
              isModal={isSmallBreakpoint}
              hideCloseButton
              onClose={() => setPaywallConfig?.()}
              onSuccess={() => {}}
              setFunnelType={() => {}}
            />
          </PageSlide>
        ) : (
          <PageSlide ltr p="24px" pb="96px" pt="80px" gap="24px">
            {comicData.crowdfunding_campaign ? (
              <CrowdfundingBanner
                crowdfundingCampaign={comicData.crowdfunding_campaign}
              />
            ) : null}
            <ComicDetailsHeader comicData={comicData} />
            <Stats
              stats={[
                {
                  value: formatLargeNumber(comicData?.total_pageviews ?? 0),
                  text: t("components.slideInMenu.comicDetailsMenu.views"),
                },
                {
                  value: comicData?.total_favorites,
                  text: t("components.slideInMenu.comicDetailsMenu.shelves"),
                },
                {
                  value: comicData?.total_releases,
                  text: t("components.slideInMenu.comicDetailsMenu.releases"),
                },
                {
                  value: getAudienceShort(comicData?.comic_facets),
                  text: t("components.slideInMenu.comicDetailsMenu.audience"),
                },
              ]}
            />
            <ComicLabels comicData={comicData as Comic} />
            {comicData?.artist && (
              <>
                <Divider />
                <ArtistSection artist={comicData?.artist} />
              </>
            )}
            <Divider />
            <ShowMoreSection collapsedHeight={150} expandedMarginBottom="24px">
              <ComicDescription comicData={comicData} />
            </ShowMoreSection>
            <ComicDetailsTabs comicData={comicData} />
            <Box height="96px" />
            {paywallConfig ? null : (
              <ComicDetailsBottomBar comicData={comicData as Comic} />
            )}
          </PageSlide>
        )}
      </AnimatePresence>
    </>
  );
}
